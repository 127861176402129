@tailwind base;

.ac__multi-value {
  @apply text-gray-800;
  @apply bg-white;
  @apply rounded-full;
  @apply border;
  @apply border-gray-400;
}

.ac__menu {
  @apply z-10;  
}

.ac__multi-value__label {
  @apply leading-none;
  @apply pl-4;
  @apply py-2;
}

.ac__multi-value__remove {
  @apply rounded-tr-full;
  @apply rounded-br-full;
  @apply pr-2;
}

.ac__multi-value__remove:hover {
  @apply bg-gray-100;
}

.ac__multi-value {
  @apply p-0;
}

.ac__option {
  @apply bg-white;
}

.ac__option--is-focused {
  @apply bg-green-200/75;
}

.ac__option--is-selected {
  @apply bg-green-50/50;
  @apply text-black;
}

.ac__option:hover {
  @apply bg-green-200;
}

textarea {
  border: 1px solid #ddd;
  @apply py-6;
  @apply px-5;
}

p {
  @apply font-sans;
  @apply text-base;
  @apply leading-normal;
}
a {
  @apply text-base;
  @apply text-green-500;
}
a:hover {
  @apply text-green-800;
}
a.normal:visited {
  @apply text-green-700;
}
div.MuiAccordion-root.Mui-expanded {
  margin: 0;
}
div.MuiDialogContent-root {
  padding: 0;
}

div.p-none h2.MuiTypography-h2,
div.p-none h3.MuiTypography-h3 {
  @apply pt-0;
  @apply pb-0;
}

form .MuiTypography-h2 {
  @apply pt-8;
  @apply pb-8;
}

form .MuiTypography-h3 {
  @apply pt-4;
  @apply pb-4;
}

.Toastify__toast-body {
  @apply whitespace-pre-line;
}

.MuiButton-outlined {
  @apply border-gray-300 !important;
  @apply bg-white !important;
  @apply text-green-500 !important;
}

.MuiButton-outlined:hover {
  border-color: #3b604c !important;
  color: #3b604c !important;
}

.MuiButton-outlinedSecondary {
  @apply border-gray-300 !important;
  @apply bg-white !important;
}

.MuiButton-containedPrimary {
  @apply bg-green-500;
}

.MuiButton-outlinedSecondary:hover {
  @apply border-green-600 !important;
  @apply text-green-600 !important;
}

:focus {
  outline: none !important;
}

/* User research indicates that number input controls need to go (CSCOIVA-1315) */
/* Chrome and others */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.breadcumbs-nav a {
  @apply underline;
  @apply text-sm;
}

.rajoite h4 {
  padding-bottom: 0;
}

.htmlContent ul {
  @apply list-disc;
  @apply p-revert;
}

.htmlContent > ul {
  @apply p-0;
  @apply list-none;
}

@tailwind components;
@tailwind utilities;

.flex-2 {
  -webkit-flex: 2;
  flex: 2;
}

[data-reach-skip-link]:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  z-index: 1200;
}

.MuiButton-contained {
  box-shadow: none !important;
}

.MuiButton-contained:hover {
  box-shadow: none !important;
}

.MuiCollapse-root {
  transition-duration: 0s !important;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
}

.tooltip-container {
  border: 0.5rem solid rgb(0 0 0 / 10%);
  left: 0.5rem !important;
  padding: 1rem !important;
}

.blue-theme-tooltip-icon {
  @apply text-blue-200;
}

.blue-theme-tooltip-icon:hover {
  @apply text-blue-300;
}