/* roboto-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto/roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"),
    url("../fonts/Roboto/roboto-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/Roboto/roboto-v20-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/Roboto/roboto-v20-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/Roboto/roboto-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/Roboto/roboto-v20-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Roboto/roboto-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("../fonts/Roboto/roboto-v20-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/Roboto/roboto-v20-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/Roboto/roboto-v20-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/Roboto/roboto-v20-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/Roboto/roboto-v20-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Roboto/roboto-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("../fonts/Roboto/roboto-v20-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/Roboto/roboto-v20-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/Roboto/roboto-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/Roboto/roboto-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/Roboto/roboto-v20-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}
